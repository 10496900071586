(() => {
  angular.module('app').controller('host.views.administration.panelInfoRequestForm.index', PanelInfoRequestFormController);

  PanelInfoRequestFormController.$inject = [
    '$scope',
    'abp.services.app.panelInfo',
  ];

  function PanelInfoRequestFormController(
    $scope,
    panelInfoSvc,
  ) {
    const vm = this;
    vm.isEdit = false;

    vm.viewForm = viewForm;
    vm.toggleEdit = toggleEdit;
    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      getPanelInfoFormLink();
    }

    function getPanelInfoFormLink() {
      vm.loading += 1;
      panelInfoSvc
        .getPanelInfoFormLink()
        .success((data) => {
          vm.requestFormLink = data;
          vm.originalFormLink = data;
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }

    function viewForm() {
      window.open(vm.requestFormLink);
    }

    function toggleEdit(flag) {
      vm.isEdit = flag;
    }

    function cancel() {
      if (vm.requestFormLink === vm.originalFormLink) {
        toggleEdit(false);
        return;
      }

      abp.message.confirm(
        App.localize('UnsavedChangesConfirmation'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            toggleEdit(false);
            vm.requestFormLink = vm.originalFormLink;
          }
        }
      );
    }

    function save () {
      vm.loading += 1;

      if (!$scope.panelInfoRequestForm.$valid) return;
      
      panelInfoSvc
        .updatePanelInfoFormLink( { id: vm.requestFormLink } )
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          vm.originalFormLink = vm.requestFormLink;
        })
        .finally(() => {
          vm.isEdit = false;
          vm.loading -= 1;
        })
    }
  }
})();
