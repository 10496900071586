(() => {
  angular.module('app').component('hostClinicsPanelNotificationsEditorComponent', {
    require: {
      container: '^hostClinicsPanelEditorContainerComponent',
    },
    bindings: {
      data: '<',
      panelType: '<',
    },
    templateUrl: require('./panelNotificationsEditor.component.html'),
    controller: PanelNotificationsEditorController,
    controllerAs: 'vm',
  });

  PanelNotificationsEditorController.$inject = [
    '$scope',
    'Hms.MultiTenancy.TenantClinicType',
    'Abp.Configuration.Setting',
  ];

  function PanelNotificationsEditorController($scope, enumTenantClinicType, constsAbpConfig) {
    function init() {}

    const vm = this;
    vm.constsAbpConfig = constsAbpConfig;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = false;
    vm.isEditing = false;

    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;

    vm.$onInit = () => {
      vm.container.addWidget(this);
    };

    vm.$onChanges = () => {
      vm.isCreate = vm.data ? !vm.data.id : false;
    };

    vm.isDirty = () =>
      $scope.panelCreateEditNotificationsForm && $scope.panelCreateEditNotificationsForm.$dirty;

    vm.setPristine = () => {
      $scope.panelCreateEditNotificationsForm.$setPristine();
    };

    // Force validation on this form.
    // True if valid, otherwise, false.

    vm.validateForm = () => {
      const form = $scope.panelCreateEditNotificationsForm;
      App.touchFormErrors(form);
      return form.$valid;
    };

    // Generate the payload from this form to send to ClinicAppService API.

    vm.getPayload = (settings) => {
      _.extend(settings, {
        notifications: vm.data,
      });
    };

    // Ask container to save this widget.

    vm.save = () => vm.container.saveWidget(vm);

    init();
  }
})();
