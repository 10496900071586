(() => {
  angular.module('app').controller('common.views.users.loginActivities.index', LoginActivitiesController);
  LoginActivitiesController.$inject = [
    'appSession',
    '$scope',
    '$state',
    '$stateParams',
    'moment',
    'appConstants',
    'abp.services.app.userLogin'
  ];
  function LoginActivitiesController(
    appSession,
    $scope,
    $state,
    $stateParams,
    moment,
    appConstants,
    userLoginSvc,
  ) {
    const vm = this;

    const userFullName = $stateParams.userFullName
      ? $stateParams.userFullName
      : appSession.user.name;

    vm.pageTitle = App.localize('LoginActivitiesTitle', userFullName);

    vm.requestParams = {
      userFullName: $stateParams.userFullName || null,
      userName: $stateParams.userName || null,
      tenancyName: $stateParams.tenancyName || null,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
    };

    vm.dateRangeModel = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    };

    vm.dateRangeOptions = angular.extend({}, App.createDateRangePickerOptions(), {
      timePicker: true,
    });

    if ($stateParams.startDate)
      vm.dateRangeModel.startDate = moment(decodeURIComponent($stateParams.startDate));
    if ($stateParams.endDate)
      vm.dateRangeModel.endDate = moment(decodeURIComponent($stateParams.endDate));

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      useExternalPagination: true,
      columnDefs: [
        {
          enableSorting: false,
          displayName: App.localize('Timestamp'),
          field: 'timeStamp',
          minWidth: 150,
        },
        {
          enableSorting: false,
          displayName: App.localize('Status'),
          field: 'status',
          cellTemplate: 'statusTemplate',
          minWidth: 100,
        },
        {
          enableSorting: false,
          displayName: App.localize('IpAddress'),
          field: 'ipAddress',
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getLoginAttempts)
        );
      },
      data: [],
    };

    vm.getLoginAttempts = getLoginAttempts;

    init();

    function init() {
      getLoginAttempts();
    }

    function registerParams() {
      let startDate = null;
      let endDate = null;
      if (vm.dateRangeModel.startDate) startDate = vm.dateRangeModel.startDate.format();
      if (vm.dateRangeModel.endDate) endDate = vm.dateRangeModel.endDate.format();

      $state.transitionTo(
        $state.current,
        {
          userFullName: vm.requestParams.userFullName,
          userName: vm.requestParams.userName,
          tenancyName: vm.requestParams.tenancyName,
          startDate: encodeURIComponent(startDate),
          endDate: encodeURIComponent(endDate),
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
        },
        {
          notify: false,
        }
      );
    }
    
    function getLoginAttempts() {
      registerParams();
      vm.loading += 1;

      userLoginSvc
        .getUserLogins($.extend({}, vm.requestParams, vm.dateRangeModel))
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;

          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }
  }
})();