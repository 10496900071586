import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'host.views.corporates.createOrEditSubsidiaryModal',
      CreateOrEditSubsidiaryModalController
    );

  CreateOrEditSubsidiaryModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.subsidiary',
    'abp.services.app.commonLookup',
    'subsidiaryId',
    'corporateId',
    'Hms.AccountOwners.PaymentAccountNumberMode',
    'Hms.MultiTenancy.Tenant',
    'Abp.Configuration.Setting',
  ];

  function CreateOrEditSubsidiaryModalController(
    $uibModalInstance,
    subsidiarySvc,
    commonLookupSvc,
    subsidiaryId,
    corporateId,
    enumPaymentAccountNumberMode,
    constsTenant,
    constsAbpConfig
  ) {
    const vm = this;
    vm.constsTenant = constsTenant;
    vm.constsSetting = constsAbpConfig;
    vm.loading = 0;
    vm.saving = 0;
    vm.subsidiary = null;
    vm.isEditing = false;
    vm.permissions = {
      manage: abp.auth.isGranted('Host.Corporates.Subsidiaries.Manage'),
      finance: abp.auth.isGranted('Host.Corporates.Subsidiaries.Finance'),
    };
    vm.generateNewAcButtonClicked = false;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.enums = {};
    vm.enums.paymentAccountNumberMode = enumPaymentAccountNumberMode;

    function init() {
      lookupIndustries();
      vm.isCreate = !subsidiaryId;
      vm.loading += 1;
      vm.enableFinance = !vm.isCreate && vm.permissions.finance;
      subsidiarySvc
        .getSubsidiaryForEdit({
          id: subsidiaryId,
          corporateId,
        })
        .success((data) => {
          vm.subsidiary = data;
          // if payment mode is VAN generation
          if (
            vm.subsidiary.paymentAccountNumberMode ===
            vm.enums.paymentAccountNumberMode.VanGeneration.id
          ) {
            if (!vm.isCreate && !_.isNil(data.virtualAccountNumber)) {
              vm.rollingNumber = data.virtualAccountNumber.substr(5, 16);
            }
            prepareAccountNumbers(data.subsidiariesPayerAccounts);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function lookupIndustries() {
      vm.loading += 1;
      commonLookupSvc
        .getIndustries()
        .success((data) => {
          vm.industries = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    vm.save = () => {
      vm.saving += 1;
      vm.subsidiary.virtualAccountNumber = vm.hmsAcNumber + vm.rollingNumber;
      subsidiarySvc
        .createOrUpdateSubsidiary(vm.subsidiary)
        .success((data) => {
          if (data) {
            swal({
              title: App.localize('SubsidiaryDeactivatedSuccessfully'),
              type: 'success',
              text: App.localize('SubsidiaryHasActiveSubscriptionNotificationMessage'),
              showConfirmButton: true,
              allowEscapeKey: false,
            });
            App.swal.disableButtons(5);
          } else {
            abp.notify.info(App.localize('SuccessfullySaved'));
          }

          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    };

    vm.toggleEdit = (flag) => {
      vm.isEditing = flag;
    };

    vm.cancel = () => {
      $uibModalInstance.dismiss();
    };

    vm.copyToClipboard = () => {
      const element = document.createElement('input');
      element.value = vm.hmsAcNumber + vm.rollingNumber;
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      abp.notify.success(App.localize('VirtualAccountNumberCopied'));
    };

    vm.generateNewAcConfirmation = () => {
      const confirmMsg = `
        ${App.localize('GeneratingNewVirtualAccountNumberInfo')}
        ${App.localize('Corporate')}: ${vm.subsidiary.tenantName}
        ${App.localize('Subsidiary')}: ${vm.subsidiary.name}
      `;
      abp.message.confirm(confirmMsg, App.localize('ConfirmedTitle'), (d) => {
        if (d) {
          vm.generateNewAcButtonClicked = true;
          subsidiarySvc.generateNewVirtualAccountNumber().success((data) => {
            vm.hmsAcNumber = data.slice(0, 5);
            const newRollingNumber = data.slice(5);

            if (!vm.rollingNumbers.includes(newRollingNumber)) {
              vm.rollingNumbers.push(data.slice(5));
            }

            vm.rollingNumber = newRollingNumber;
          });
        }
      });
    };

    init();

    function prepareAccountNumbers(accountNumbers) {
      const hmsAccNumbers = [];
      const rollingNumbers = [];

      _.each(accountNumbers, (d) => {
        if (!hmsAccNumbers.includes(d.slice(0, 5))) {
          hmsAccNumbers.push(d.slice(0, 5));
        }

        if (!rollingNumbers.includes(d.slice(5))) {
          rollingNumbers.push(d.slice(5));
        }
      });

      vm.rollingNumbers = rollingNumbers;
      vm.hmsAcNumber = _.head(hmsAccNumbers);
    }
  }
})();
